.ngx-org {
  &-image {
    background-repeat: no-repeat;
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    background-color: white;
    border-radius: 50%;
    padding: 0.25em;
  }

  &-name {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  &-title {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
}




.ngx-org {
  &-horizontal {
    padding: 1em 0;
  }

  &-vertical {
    padding: 0 1em;
  }

  &-border {
    border: 1px solid darkgrey;
  }

  &-box {
    padding: 0.5em 1em;
    border-radius: .2em;
    box-shadow: 0.05em 0.05em 0.2em 0.05em #0000002b;
  }

  &-connector {
    &-horizontal {
      width: 1em;
    }

    &-vertical {
      height: 1em;
    }
  }
}
